import { Fragment, useState, useEffect } from "react";
import IOrganism from "./interface";
import { Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useUser } from "@hooks";
import DocumentsOrganism from "@organisms/Documents";
import NotesOrganism from "@organisms/Notes";
import { InspectionModel } from "@models/InspectionModel";
import { StyledFuncWrapper } from "./styled";
import { Trans, t } from "@lingui/macro";
import InspectionDetailFormOrganism from "@organisms/InspectionDetailForm";
import InspectionDetailPreviewOrganism from "@organisms/InspectionDetailPreview";
import InspectionDetailsRates from "./Rates";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ActionsEventsOrganism from "@organisms/ActionsEvents";
import { Tab, Box, Tabs } from "@mui/material";
import { IDocument } from "@interfaces/IDocument";
import { useSelector } from "react-redux";
import { IType } from "@interfaces/IType";

const InspectionDetailTabOrganism = ({
  inspection,
  onSave,
  isEdit,
  onCancel,
}: IOrganism) => {
  const user = useUser();
  const [editGeneric /*, setEditGeneric*/] = useState<boolean>(true); // non in uso??
  const [ratesIsZero, setRatesIsZero] = useState<boolean>(false);
  // const [ infoIsExpanded, setInfoIsExpanded ] = useState<boolean>(true);
  const [inspectionFormDirty, setInspectionFormDirty] = useState<boolean>(true);
  const documentsTypes = useSelector(
    (state: any) => state.filters.filters.types?.documents,
  );
  const [activeDocumentsFilter, setActiveDocumentsFilter] = useState<number>(documentsTypes ? documentsTypes[0].id : null);

  useEffect(() => {
    setActiveDocumentsFilter(documentsTypes ? documentsTypes[0].id : null)
  }, ([documentsTypes]))

  const filterDocuments = (document: IDocument) => {
    const documentsTypeId = documentsTypes?.find((documentsType: IType) => documentsType.alias === 'documents').id;
    const poTypeId = documentsTypes?.find((documentsType: IType) => documentsType.alias === 'pos').id;

    return activeDocumentsFilter ===
      documentsTypeId ? document.type?.alias === 'documents'
      : activeDocumentsFilter === poTypeId ? document.type?.alias === 'pos'
      : document.type?.alias === 'reports'
    }

  /*
    const clickEditGeneric = (event: any, edit:boolean) => {
        setEditGeneric(edit);
        if(infoIsExpanded) {
            event.stopPropagation();
        }
    }
    */

  const handleOnInspectionDetailDirty = (isDirty: boolean) => {
    setInspectionFormDirty(isDirty);
  };

  const accordionChangeStatus = (expanded: boolean) => {
    // setInfoIsExpanded(expanded);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12} md={7}>
          <StyledFuncWrapper>
            <Accordion
              onChange={(event: any, expanded: boolean) =>
                accordionChangeStatus(expanded)
              }
              defaultExpanded={true}
              elevation={0}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span style={{ display: "flex" }}>
                  <span>
                    <Typography variant="h5">
                      <b>
                        <Trans>Inspection info</Trans>
                      </b>
                    </Typography>
                  </span>
                </span>
                {user.isAdmin() ? (
                  <Fragment>
                    {/*
                                                !editGeneric ?
                                                    <Tooltip title={t`Edit activity`} placement="top">
                                                        <IconButton onClick={(event: any) => clickEditGeneric(event, true)}>
                                                            <EditIcon color='success'></EditIcon>
                                                        </IconButton>
                                                    </Tooltip>
                                                : <Tooltip title={t`Cancel`} placement="top">
                                                    <IconButton onClick={(event: any) => clickEditGeneric(event, false)}>
                                                        <CancelIcon color='success'></CancelIcon>
                                                    </IconButton>
                                                </Tooltip>
                                            */}
                  </Fragment>
                ) : (
                  <></>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {!editGeneric && (
                  <InspectionDetailPreviewOrganism inspection={inspection} />
                )}
                {editGeneric && (
                  <InspectionDetailFormOrganism
                    propagateDirtyStatus={true}
                    onDirtyChange={handleOnInspectionDetailDirty}
                    onSave={(inspection: InspectionModel) =>
                      onSave ? onSave(inspection) : ""
                    }
                    inspection={inspection}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </StyledFuncWrapper>
        </Grid>
        <Grid item sm={12} md={5}>
          {!user.isInspector() ? (
            <Fragment>
              <StyledFuncWrapper>
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <span>
                      <Typography variant="h5">
                        <b>
                          <Trans>Rates</Trans>&nbsp;
                        </b>
                        {ratesIsZero && (
                          <ReportProblemIcon
                            className="warn"
                            color="warning"
                            fontSize="small"
                          />
                        )}
                      </Typography>
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <InspectionDetailsRates
                      ifZero={(is: boolean) => setRatesIsZero(is)}
                      inspection={inspection}
                    />
                  </AccordionDetails>
                </Accordion>
              </StyledFuncWrapper>
              <StyledFuncWrapper>
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <span>
                      <Typography variant="h5">
                        <b>
                          <Trans>Actions & events</Trans>
                        </b>
                      </Typography>
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ActionsEventsOrganism
                      disableButtons={inspectionFormDirty}
                      inspection={inspection}
                    ></ActionsEventsOrganism>
                  </AccordionDetails>
                </Accordion>
              </StyledFuncWrapper>
              <StyledFuncWrapper>
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <span>
                      <Typography variant="h5">
                        <b>
                          <Trans>Docs</Trans>
                        </b>
                      </Typography>
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Tabs value={activeDocumentsFilter} onChange={(event: React.SyntheticEvent, newValue: number) => {
                      setActiveDocumentsFilter(newValue);
                    }}>
                      {
                        documentsTypes?.map((documentType: IType) => {
                          return (<Tab value={documentType.id} key={documentType.id} label={documentType.name} />)
                        })
                      }
                    </Tabs>
                    <DocumentsOrganism
                      inspection={inspection}
                      sharepoint_dir={activeDocumentsFilter === documentsTypes?.find((documentsType: IType) => documentsType.alias === 'pos').id ? inspection.pos?.sharepoint_dir : inspection.sharepoint_dir}
                      showModelFilter={activeDocumentsFilter === documentsTypes?.find((documentsType: IType) => documentsType.alias === 'reports').id}
                      filter={filterDocuments}
                      uploadDisabled={activeDocumentsFilter === documentsTypes?.find((documentsType: IType) => documentsType.alias === 'reports').id}
                      model_id={activeDocumentsFilter === documentsTypes?.find((documentsType: IType) => documentsType.alias === 'pos').id ? inspection.pos?.id ?? 0 : inspection.id}
                      model_type={activeDocumentsFilter === documentsTypes?.find((documentsType: IType) => documentsType.alias === 'pos').id ? 'pos' : 'inspections'}
                    />
                  </AccordionDetails>
                </Accordion>
              </StyledFuncWrapper>
              <StyledFuncWrapper>
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <span>
                      <Typography variant="h5">
                        <b>
                          <Trans>Notes & description</Trans>
                        </b>
                      </Typography>
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <NotesOrganism
                      model_id={inspection.id}
                      model_type="inspections"
                    />
                  </AccordionDetails>
                </Accordion>
              </StyledFuncWrapper>
            </Fragment>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};

InspectionDetailTabOrganism.defaultProps = {
  hide: [],
  verbose: [],
};

export default InspectionDetailTabOrganism;
