import { useEffect } from "react";
import { Grid, Paper, Box } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { AutocompleteMolecule } from "@molecules/AsyncAutocomplete";
import IJobsFilterOrganism, { IFormValues, schemaA } from "./interface";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import TextFieldAtom from "@atoms/TextField";
import { StyledButtonGroupHolder } from "./styled";
import RadioGroupMolecule from "@molecules/RadioGroup";

const JobsFilterOrganism = ({ isHcm, onFormChange }: IJobsFilterOrganism) => {
  const formMethods = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: {
      job_type: 1,
      user_id: "",
      po_id: "",
      vendor_id: "",
      client_id: "",
      category_id: "",
      search: "",
    },
    reValidateMode: "onSubmit",
    resolver: yupResolver(schemaA),
  });

  const { handleSubmit, getValues, watch } = formMethods;

  let timeout: ReturnType<typeof setTimeout> | null = null;

  useEffect(() => {
    const watchAll = formMethods.watch((value, { name, type }) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        handleFormSubmit(getValues());
      }, 300);
    });
    return () => watchAll.unsubscribe();
  }, [watch, formMethods]);

  const handleFormSubmit = (data: IFormValues) => {
    onFormChange(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormProvider {...formMethods}>
          <Grid container spacing={1}>
            <Grid p={2} item md={4}>
              <StyledButtonGroupHolder
                elevation={0}
                variant="outlined"
                sx={{ position: "relative" }}
              >
                <RadioGroupMolecule
                  controlName={"job_type"}
                  control={formMethods.control}
                  radios={[
                    {
                      name: t`Jobs`,
                      id: 1,
                    },
                    {
                      name: t`Hcm`,
                      id: 2,
                    },
                  ]}
                />
              </StyledButtonGroupHolder>
            </Grid>
            <Grid item md={8}>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <Box>
                    <Paper
                      elevation={0}
                      variant="outlined"
                      sx={{ position: "relative" }}
                    >
                      <Grid container p={1} spacing={1}>
                        {!isHcm && <Grid item xs={12} sm={12} md={3}>
                          <AutocompleteMolecule
                            control={formMethods.control}
                            controlName="po_id"
                            emptyValue={null}
                            optionValue={"id"}
                            variant={"outlined"}
                            label={"Po"}
                            getOptionLabel={(item: any) => `${item.name}_${item.client_name}`}
                            storeCollection="pos"
                          ></AutocompleteMolecule>
                        </Grid> }
                        <Grid item xs={12} sm={12} md={3}>
                          <AutocompleteMolecule
                            control={formMethods.control}
                            controlName="client_id"
                            emptyValue={null}
                            optionValue={"id"}
                            variant={"outlined"}
                            label={"Client"}
                            getOptionLabel={(client: any) => `${client.name}`}
                            storeCollection="clients"
                            optionLabel={"name"}
                          ></AutocompleteMolecule>
                        </Grid>
                        {!isHcm && (
                          <Grid item xs={12} sm={12} md={3}>
                            <AutocompleteMolecule
                              control={formMethods.control}
                              controlName="category_id"
                              listId={"activities-filter-categories"}
                              storeCollection="categories.jobs"
                              emptyValue={null}
                              variant={"outlined"}
                              optionValue={"id"}
                              getOptionLabel={(category: any) =>
                                `${category.name}`
                              }
                              label={<Trans>Service</Trans>}
                            ></AutocompleteMolecule>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={3}>
                          <TextFieldAtom
                            controlName="search"
                            variant={"outlined"}
                            label={
                              !isHcm ? (
                                <Trans>Notification/Job ID</Trans>
                              ) : (
                                <Trans>Code ID</Trans>
                              )
                            }
                          ></TextFieldAtom>
                        </Grid>
                        {isHcm && (
                          <Grid item xs={12} sm={12} md={3}>
                            <AutocompleteMolecule
                              control={formMethods.control}
                              controlName="user_id"
                              listId={"hcm-filter-inspectors"}
                              storeCollection="users"
                              emptyValue={null}
                              variant={"outlined"}
                              optionValue={"id"}
                              getOptionLabel={(user: any) =>
                                `${user.full_name}`
                              }
                              label={<Trans>Inspector</Trans>}
                            ></AutocompleteMolecule>
                          </Grid>
                        )}
                        {isHcm && (
                          <Grid item xs={12} sm={12} md={3}>
                            <AutocompleteMolecule
                              control={formMethods.control}
                              controlName="vendor_id"
                              listId={"hcm-filter-vendors"}
                              storeCollection="vendors"
                              emptyValue={null}
                              variant={"outlined"}
                              optionValue={"id"}
                              getOptionLabel={(vendor: any) => `${vendor.name}`}
                              label={<Trans>Vendor</Trans>}
                            ></AutocompleteMolecule>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </>
  );
};

export default JobsFilterOrganism;
